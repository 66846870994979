import React, { Component } from "react";
import Avatar from 'react-md/lib/Avatars/Avatar';
import UserLinks from "../UserLinks";
import "./ToolbarActions.scss";

class Toolbar extends Component {
  render() {
    const { config } = this.props;
    return (
      <div className="toolbar-actions">
        <div className="userlinks-container">
          <UserLinks config={config} />
        </div>
        <div className="logo-container">
          <Avatar src="/logos/logo_small_150x150.png" role="presentation" contentClassName="avatar-image" />
        </div>
      </div>
    );
  }
}

export default Toolbar;
