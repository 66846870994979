import React from "react";
import { FontIcon }  from "react-md/lib/FontIcons";
import { Link } from "gatsby";

function GetNavList(config) {
  const NavList = [
    {
      key: "Home",
      primaryText: "Home",
      leftIcon: <FontIcon primary>home</FontIcon>,
      component: Link,
      to: "/"
    }
  ];

  if (config.menuLinks) {
    config.menuLinks.forEach(section => {

      NavList.push({
        divider: true
      });

      const temp = [];
      section.menuItems.forEach(link => {
        temp.push({
          key: link.label,
          primaryText: link.label,
          leftIcon: <FontIcon primary forceSize iconClassName={link.iconClassName} />,
          component: Link,
          to: link.url
        });
      });

      NavList.push({
        key: section.sectionName,
        primaryText: section.sectionName,
        nestedItems: temp,
        leftIcon:  <FontIcon primary forceSize iconClassName={section.iconClassName} />
      });
    });

  }

  return NavList;
}
export default GetNavList;
