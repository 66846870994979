import React from 'react';
import NavigationDrawer from 'react-md/lib/NavigationDrawers/NavigationDrawer';
import ToolbarActions from "../ToolbarActions";
import Footer from "../Footer";
import Logo from "../Logo";
import GetNavList from "./NavList";
import "./Navigation.scss";

class Navigation extends React.Component {
  render() {
    const { children, config, LocalTitle } = this.props;
    const footerLinks = LocalTitle !== "About";

    return (
      <NavigationDrawer
        // drawerTitle={config.siteTitleShort}
        drawerHeader={<Logo config={config} />}
        toolbarTitle={LocalTitle}
        contentClassName="main-content"
        navItems={GetNavList(config)}
        mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
        tabletDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
        desktopDrawerType={NavigationDrawer.DrawerTypes.PERMANENT}
        toolbarActions={<ToolbarActions config={config} />}
      >
        <div className="main-container">{children}</div>
        <Footer userLinks={footerLinks} />
      </NavigationDrawer>
    );
  }
}

export default Navigation
