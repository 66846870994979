module.exports = {
  siteTitle: "Mindful Montessori Early Learning Center", // Site title.
  siteTitleShort: "Mindful Montessori", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Mindful Montessori School", // Alternative site title for SEO.
  siteLogo: "/logos/tower-200x200.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.mindfulmontessori.com", // Domain of your website.
  fixedFooter: false, // Whether the footer component is fixed, i.e. always visible
  siteDescription: "An early learning center based on the Montessori system.", // Website description used for RSS feeds/meta description tag.
  siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  siteGATrackingID: "UA-147059953-1", // Tracking code ID for google analytics.
  pathPrefix: "/",
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  userName: "Web Master",
  googleBusinessAPI: {
      "client_id": "71137676553-dden8fctq5lpdi0urh4asfs34nh7n4um.apps.googleusercontent.com",
      "project_id": "mindful-montessori-app",
      "auth_uri": "https://accounts.google.com/o/oauth2/auth",
      "token_uri": "https://oauth2.googleapis.com/token",
      "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
      "client_secret": "R9MgJUHuJzJ4SgmmNRjKOgaR",
      "redirect_uris": [
        "https://www.mindfulmontessori.com/authcallbak"
      ]
  },
  menuLinks: [
      {
        sectionName: "Our Programs",
        iconClassName: "fa fa-link",
        menuItems: [
          {
            label: "Infant",
            url: "/programs/infant",
            iconClassName: "fa fa-minus"
          },
          {
            label: "Toddler",
            url: "/programs/toddler",
            iconClassName: "fa fa-minus"
          },
          {
            label: "Primary",
            url: "/programs/primary",
            iconClassName: "fa fa-minus"
          },
          {
            label: "Nutrition",
            url: "/programs/nutrition",
            iconClassName: "fa fa-minus"
          },
        ]
      },
      {
        sectionName: "Admissions",
        iconClassName: "fa fa-link",
        menuItems: [
          {
            label: "Information",
            url: "/admissions/information",
            iconClassName: "fa fa-minus"
          },
          {
            label: "Schedule Visit",
            url: "/admissions/visit",
            iconClassName: "fa fa-minus"
          },
          {
            label: "Apply Now",
            url: "/admissions/apply",
            iconClassName: "fa fa-minus"
          },
          {
            label: "Admission Form",
            url: "/admissions/admission",
            iconClassName: "fa fa-minus"
          },
        ]
      },
      {
        sectionName: "About Us",
        iconClassName: "fa fa-link",
        menuItems: [
          {
            label: "Who are we?",
            url: "/about/who",
            iconClassName: "fa fa-minus"
          },
          {
            label: "Our Mission",
            url: "/about/mission",
            iconClassName: "fa fa-minus"
          },
          {
            label: "Our Values",
            url: "/about/values",
            iconClassName: "fa fa-minus"
          },
          {
            label: "Our Team",
            url: "/about/team",
            iconClassName: "fa fa-minus"
          },
          {
            label: "Careers",
            url: "/about/careers",
            iconClassName: "fa fa-minus"
          },
          {
            label: "Contact Us",
            url: "/about/contact",
            iconClassName: "fa fa-minus"
          }
        ]
      },
  ],
  userLinks: [
    {
      label: "Facebook",
      url: "https://facebook.com/mindfulmontessori",
      iconClassName: "fa fa-facebook"
    },
    {
      label: "Instagram",
      url: "https://www.instagram.com/mindfulmontessori_elc/",
      iconClassName: "fa fa-instagram"
    },
    {
      label: "Email",
      url: "mailto:info@mindfulmontessori.com",
      iconClassName: "fa fa-envelope"
    }
  ],
  copyright: "Copyright © 2018. Mindful Montessori." // Copyright string for the footer of the website and RSS feed.
};
