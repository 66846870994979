import React from "react";
import UserLinks from "../UserLinks";
import config from "../../../data/config";
import "./Footer.scss";

class Footer extends React.Component {
  render() {
    const { userLinks } = this.props;
    const { copyright, fixedFooter } = config;
    if (!copyright) {
      return null;
    }
    return (
      <footer className={fixedFooter ? "footer footer-fixed" : "footer"}>
        {userLinks ? <UserLinks config={config} labeled /> : null}
      </footer>
    );
  }
}

export default Footer;
