import React from 'react'
import Media from "react-md/lib/Media/Media";
import "./Logo.scss";

class Logo extends React.Component {
  render() {
    const { config } = this.props;

    return (
      <Media className="container">
        <img src="/logos/logo_nb_25.png" alt={config.siteTitleShort} className="logo" />
      </Media>
    );
  }
}

export default Logo;
